import React, { useState } from 'react';
import './App.css';

// 01-01-2022 RJ 0.0.0 Arthur interface to Zarquon
// 02-09-2022 RJ 0.2.0 Added authentication and upload facility
// 05-09-2022 RJ 0.2.1 Improvements to login and upload
// 21-09-2022 RJ 0.2.2 Download now much more complex and requires authentication
// 05-10-2022 RJ 0.2.3 Mainly password reset, some improvements to key handling
// 20-10-2022 RJ 0.2.4 Changes to handle API better matching vroomfondel 0.3.0, removed FileTree
// 09-01-2023 RJ 0.2.5 Updated NHS Digital -> NHS England, bumped (c) year to 2023
// 23-01-2023 RJ 0.2.6 Updated Schedule display to be prettier and include release date
// 05-05-2023 RJ 0.2.7 Small change to Products to allow 'Other' to spread to several columns
// 22-05-2023 RJ 0.2.8 Give nice description of the data in the releases screen
// 20-12-2023 RJ 0.2.9 Added in source examination and manipulation
// 22-04-2024 RJ 0.2.10 Set title etc. from environment variables

// Remember to change the VERSION number below!

import {
    BrowserRouter as Router,
    Route,
    Routes,
    useNavigate,
} from "react-router-dom";

import { GrLogout, GrUserSettings } from "react-icons/gr";

import { Login, Logout, useUser, Need, useAllowed, getKeys, PasswordReset } from "./Auth.jsx";

// Probably should be using something like this page:
// https://www.digitalocean.com/community/tutorials/how-to-add-login-authentication-to-react-applications
// to provide authentication rather than the basic auth

// Need to format this more nicely as follows...

// +----------------------------------------+
// |              Title area                |
// +--------+-------------------------------+
// | Menu | Menu | Menu |                   |
// +--------+-------------------------------+
// | Side   |                               | } <- This section very dependent
// | Side   |                               | } <- on the menu item selected
// | Bar    | Tree selection                | }
// | Menu   |                               | }
// |        +-------------------------------+ }
// |        | File display                  | }
// |        |                               | }
// +--------+-------------------------------+
// | Footer with © copyright etc.           |
// +----------------------------------------+

// The title area and side bar menu are fixed for the entire app
// The right panel depends on what is selected in the side bar
// The Tree/file example is for extracts but might not fit other things

// "international":    col_int,        # Reddish
// "clinical":         col_clin,       # Purple
// "drugs":            col_drugs,      # Orange
// "pathology":        col_path,       # Green
// "edition":          col_edition,    # Dark blue
// "crossmap":         col_xmap,       # Light brown

// import { Releases } from "./Releases.js";
// import { Extracts } from "./Extracts.js";
import { Schedule } from "./Schedule.jsx";
import { Sources } from "./Sources.jsx";
import { ShowProducts } from "./Products.js";
// import { Upload } from "./Upload.jsx";

const VERSION = "0.2.10"     // Displayed bottom, right

function MenuItem(props) {
    const navigate = useNavigate();

    var disabled = props.disabled;
    var reason = "Not available";

    const keys = props.keys;

    if (!useAllowed(keys)) {
        // console.log("Disabling");
        disabled = true;
        reason = "You do not have access";
    }

    const name = props.name;
    const link = props.link === undefined ? name.toLowerCase() : props.link;

    if (disabled)
        return (
            <div title={reason} className="main-menu-item-disabled"> {name}</div>
        );

    return (
        <div className="main-menu-item" onClick={() => navigate(link)}> {name}</div>
    );
}

function UserButton(props) {
    let navigate = useNavigate();

    const name = props.name;

    return (
        <div className="user-button" onClick={() => navigate("/user")}>
            {name} &nbsp; <GrUserSettings />
        </div>
    );
}

function LogoutButton() {
    return (
        <div className="logout-button" onClick={() => { Logout(); window.location.reload(false); }}>
            logout &nbsp; <GrLogout />
        </div>
    );
}

function UserSettings() {
    return <div>User settings will be here in time...</div>
}

function MainScreen(props) {

    const user = props.user;
    const [keys, setKeys] = useState();
    // const keys = useKeys();

    var year = 2024;

    // console.log(user);
    // console.log(keys);

    if (! keys) {
        getKeys(setKeys);
        return <div>Please wait...</div>;
    }

    var title = "The Great Prophet Zarquon!";
    var image = "/zarquon.jpg"
    if (process.env.REACT_APP_ARTHUR_TITLE) {
        title = process.env.REACT_APP_ARTHUR_TITLE;
        image = "/arkleseizure.jpg";
    }
    title = <div>The Great <s>Prophet Zarquon</s> Green Arkleseizure!</div>;

    const background = process.env.REACT_APP_ARTHUR_BACKGROUND ? process.env.REACT_APP_ARTHUR_BACKGROUND : "#1f7f1f";

    return (
        <div className="main-screen">
            <div className="main-header" style={{backgroundColor: background}}>
                <img alt="Z" src={image} height="50px" />
                {title}
                <span className="user-info"><UserButton name={user.name}></UserButton><LogoutButton /></span>
            </div>
            <div className="main-menu">
                <MenuItem name="Releases" />
                |
                <MenuItem name="Schedule" />
                {/* |
                <MenuItem name="Upload" keys="upload" /> */}
                {/* |
                <MenuItem name="Sources" keys="sources" /> */}
                {/* |
                <MenuItem name="Explorer" />
                |
                <MenuItem name="Old Releases" link="/rels" />
                |
                <MenuItem name="Extracts" /> */}
            </div>
            <div className="main-middle" style={{backgroundColor: background}}>
                <Routes>
                    <Route path="/"             element={<Need keys="releases"> <ShowProducts />    </Need>} />
                    <Route path="/releases/:id" element={<Need keys="releases"> <ShowProducts />    </Need>} />
                    <Route path="/releases"     element={<Need keys="releases"> <ShowProducts />    </Need>} />
                    <Route path="/schedule"     element={<Need keys="schedule"> <Schedule />        </Need>} />
                    <Route path="/sources/:id"  element={<Need keys="resources">  <Sources />         </Need>} />
                    {/* <Route path="/explorer"     element={<Need keys="explorer"> You'll need to wait for the explorer</Need>} /> */}
                    {/* <Route path="/upload"       element={<Need keys="upload">   <Upload />          </Need>} /> */}
                    {/* <Route path="/rels"         element={<Need keys="oldreleases"> <Releases />        </Need>} /> */}
                    {/* <Route path="/extracts"     element={<Need keys="extracts"> <Extracts />        </Need>} /> */}
                    <Route path="/user"         element={<UserSettings />} />
                </Routes>
            </div>
            <div className="main-footer"><span /><span>Copyright © {year} NHS England</span>Arthur interface version {VERSION} &nbsp; </div>
        </div>
    );
}

function App() {

    const [user, setUser] = useUser();

    if (window.location.pathname.startsWith("/password-reset/")) {
        return <PasswordReset/>
    }

    // console.log(user);
    if (!user) {
        // console.log("Calling Login...")
        return <Login setUser={setUser} />
    }

    // console.log("Plain app with user info");
    return (
        <Router>
            <MainScreen user={user} />
        </Router>
    );
}

export default App;
